@import '~bootstrap/scss/bootstrap';

#root {
  padding: 1rem;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
html {
    height: 100%;
    width: 100%;
  }
.container {
    z-index: 1;
}
  body {
    margin: 0;
    font-family: AlbraGrotesk, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100%;
    width: 100%;
    background: url('https://1240369105.rsc.cdn77.org/moviechecklist-cameraearlyaccess/background-image-mobile.jpg') black;
    background-size: cover!important;
    display: flex;
    background-repeat: no-repeat!important;
    background-position: center center!important;
    align-items: center;
    justify-content: center;
    @media only screen and (min-width: 768px) {
      background: url('https://1240369105.rsc.cdn77.org/moviechecklist-cameraearlyaccess/background-image-tablet.jpg') black;
    }
    @media only screen and (min-width: 1200px) {
      background: url('https://1240369105.rsc.cdn77.org/moviechecklist-cameraearlyaccess/background-image-desktop.jpg') black;
    }
  }
  .body-content {
    animation: fadeInDown .5s forwards;
    background-color: #fff;
    border-radius: .75rem;
    box-shadow: 5px 5px 20px rgba(0,0,0,.4);
    padding:  4rem 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    position: relative;
    @media only screen and (min-width: 992px) {
      width: 838px;
      padding: 6rem 1.5rem 2rem;
    }

    .logo {
      max-width: 164px;
      @media only screen and (min-width: 768px) {
        max-width: 240px;
      }
      @media only screen and (min-width: 992px) {
        max-width: 100%;
      }
    }
.hallmark-logo {
  margin-top: 3rem;
  max-width: 86px;
  @media only screen and (min-width: 768px) {
    max-width: 110px;
  }
  @media only screen and (min-width: 992px) {
    max-width: 100%;
  }
}

    .divider {
        background-color: #802595;
        opacity: 1;
        width: 3rem;
        margin: 2rem auto;
        height: 5px;
        @media only screen and (min-width: 992px) {
          width: 5rem;
          height: 7px;
          margin: 3rem auto;
        }
    }
    &:before {
      content: '';
      height: 94%;
      width: 91.5%;
      border: 2px solid #1C2C4C;
      position: absolute;
      left: 4%;
      top: 3%;
      @media only screen and (min-width: 576px) {
        height: 94%;
        top: 3%;
        width: 94%;
        left: 3%;
    }
    @media only screen and (min-width: 768px) {
      left: 2%;
      width: 96%;
    }
      @media only screen and (min-width: 992px) {
        border: 4px solid #1C2C4C;
        left: 3%;
        width: 94%;
      }
    }
    p {
      color: #802595;
      font-weight: 500;
      margin: 1rem 0 2rem;
      font-size: 1rem;
      @media only screen and (min-width: 992px) {
        font-size: 1.6rem;
        font-weight: 700;
      }
    }
    .app-store-header {
      margin-bottom: 1rem;
      margin-top: 1.5rem;
    }
  }
  .body-content
    .row {
     text-align: center;
  }
  h2 {
    color: #1C2C4C;
    font-size: .9rem;
    font-weight: 700;
    @media only screen and (min-width: 768px) {
      font-size: 1.25rem;
    }
    @media only screen and (min-width: 992px) {
      font-size: 1.5rem;
    }
  }
.corner-elements {
    .top-left,
    .top-right,
    .bottom-left,
    .bottom-right {
      position: absolute;
      background-color: #fff;
      max-width: 54px;
      width: 100%;
      height: auto;
        @media only screen and (min-width: 992px) {
             max-width: 88px;
         }
    }

  .top-left {
    left: 0;
    top: 0;
  }
  .bottom-left {
    left: 0;
    bottom: 0;
    transform: rotateX(180deg);
  }
  .top-right {
    right: 0;
    top: 0;
    transform: rotateY(180deg);
  }
  .bottom-right {
    right: 0;
    bottom: 0;
    transform: rotateZ(180deg);
  }
}
form {
  position: relative;
}
form input[type=password] {
  width: 100%;
  border: 1px solid #AFAFAF;
  border-radius: .25rem;
  height: 42px;
  padding: 0 .5rem;
  @media only screen and (min-width: 992px) {
    height: 68px;
  }
  &:focus-visible {
    outline: none;
  }
}
::placeholder {
  color: #AFAFAF;
}
.btn.btn-primary {
  align-items: center;
  border-radius: .25rem;
  text-transform: uppercase;
  margin-top: 1.25rem;
  background-color: #802595;
  border-color: #802595;
  font-weight: 900;
  font-size: .85rem;
  justify-self: center;
  letter-spacing: .05rem;
  height: 40px;
  @media only screen and (min-width: 992px) {
    height: 68px;
    font-size: 1.2rem;
  }
  i {
    margin-left: .25rem;
  }
  &:hover,
  &:active,
  &:visited,
  &:focus-within {
    background-color: darken(#802595,7%);
  }
  &:active:focus,
  &:focus {
    box-shadow: none;
  }
}

.row-tight {
  [class*="col-"] {
    &:first-of-type {
     padding-right: 6px;
    }
    &:last-of-type {
     padding-left: 6px;
    }
  }
  &.button-container {
    justify-content: center;
  }
}
.button-link {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
}
button[type="submit"] {
  background-color: transparent;
}
.arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-top: -1px;
}
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.desktop-warning {
  margin-top:  0;
  font-weight: 900;
}
.section-divider {
  margin: .25rem 0 1rem;
}
.form-input-container {
  position: relative;
 &.error input[type=password]  {
    border-color: red;
  }
}
.error-message {
  color: red;
  font-size: .6rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -16px;
}

.privacy-policy {
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 .25rem;
  margin-top: 3rem;
  ul {
    display: flex;
    justify-content: center;
    padding-left: 0;
    flex-wrap: wrap;
    margin-bottom: .75rem;
    @media only screen and (min-width: 768px) {
      margin-bottom: 1rem;
    }
  }
  li {
    margin-right: .75rem;
    list-style: none;
    font-size: .5rem;
    position: relative;
    @media only screen and (min-width: 556px) {
      font-size: .5rem;
    }
    @media only screen and (min-width: 992px) {
      margin-right: 1rem;
      font-size: .65rem;
    }
    &:last-of-type {
      margin-right: 0;
    }
    a{
    color: #1C2C4C;
    text-decoration: none;
  }
  &:first-of-type {
    &:after {
      content: '';
    }
  }
  &:after {
    content: "\1C0";
    display: flex;
    left: -7px;
    position: absolute;
    top: 0;
    bottom: 0;
    align-items: center;
    color: #1C2C4C;
    @media only screen and (min-width: 768px) {
      left: -9px;
    }
  }
}
}

.animateIn {
  .error-message {
    animation: pop .15s forwards;
  }
}
.submitting{
  position: relative;
    &:after {
    content: '';
    position: absolute;
    background-color: rgba(255,255,255,0);
    height: 20px;
    width: 20px;
    border: 3px solid rgba(255,255,255,1);
    border-right-color: rgba(255,255,255,0);
    border-radius: 500px;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    will-change: transform;
    animation: loading-spinner-anim 0.8s linear infinite;
}
.loading {
  display: none;
}
}
@keyframes pop{
  0%  {
    transform: scale(0);
  }
  75%  {
    transform: scale(1.2);
  }
  100%  {
    transform: scale(1);
  }
}
@keyframes loading-spinner-anim{
  to{
    transform: rotate(360deg);
  }
}
@keyframes fadeInDown {
  0% {
     opacity: 0;
     transform: translateY(-30px);
  }
  100% {
     opacity: 1;
     transform: translateY(0);
  }
}